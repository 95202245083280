import React, { useState } from "react";
import { Box, Typography, FormControl, Select, MenuItem, TextField, Grid, SelectChangeEvent } from "@mui/material";

// Defining the interface for the student data.
interface StudentData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  campusId: number;
}

interface AddNewStudentComponentProps {
  isAddingStudent: boolean;
  updateStudentData: (data: Partial<StudentData>) => void;
}

const AddNewStudentComponent: React.FC<AddNewStudentComponentProps> = ({
  isAddingStudent,
  updateStudentData,
}) => {
  const [selectCampus, setSelectCampus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const { value } = event.target;
    setter(value);
    updateStudentData({ [event.target.id]: value });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    updateStudentData({ email: inputEmail });
  };

  const handleCampusChange = (event: SelectChangeEvent<string>) => {
    setSelectCampus(event.target.value);
    updateStudentData({ campusId: Number(event.target.value) });
  };

  return (
    <Box>
      {isAddingStudent ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>First name</Typography>
              <TextField
                id="firstName"
                value={firstName}
                onChange={(event) => handleInputChange(event, setFirstName)}
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Last name</Typography>
              <TextField
                id="lastName"
                value={lastName}
                onChange={(event) => handleInputChange(event, setLastName)}
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Phone Number</Typography>
              <TextField
                id="phoneNumber"
                value={phoneNumber}
                onChange={(event) => handleInputChange(event, setPhoneNumber)}
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Email</Typography>
              <TextField
                id="email"
                value={email}
                onChange={handleEmailChange}
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Campus</Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  value={selectCampus}
                  onChange={handleCampusChange}
                  displayEmpty
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333333",
                    borderRadius: "8px",
                    minWidth: "184px",
                    textTransform: "capitalize",
                    ".MuiSelect-select": {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333333",
                      paddingX: "16px",
                      paddingY: "13px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #D2D2D0",
                    },
                  }}
                >
                  <MenuItem value="" disabled>Select any</MenuItem>
                  <MenuItem value="1">L1</MenuItem>
                  <MenuItem value="2">L2</MenuItem>
                  <MenuItem value="3">L3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ paddingY: "16px" }} color="#333333" variant="title4" component={"div"}>Add new subject</Typography>
        </Box>
      )}
    </Box>
  );
};

export default AddNewStudentComponent;
