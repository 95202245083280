import axios from "axios";

const apiClientFileUpload = axios.create({
  baseURL: `${process.env.REACT_APP_FILEUPLOAD_URL}`,
  // baseURL: "https://contour-api.irepo.in",
});

apiClientFileUpload.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // Use the navigate function from context to redirect

    if (error.response && error.response.status === 401) {
      // Clear cookies, localStorage, and sessionStorage
      document.cookie.split(";").forEach((cookie) => {
        const cookieName = cookie.split("=")[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
      localStorage.clear();
      sessionStorage.clear();

      // Redirect to the login page
      window.location.href = "/"
    }

    // Reject the promise with the error
    return Promise.reject(error);
  }
);

export default apiClientFileUpload;
