import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { AdminDashboard } from "./pages/AdminDashboard";
import { FileLibrary } from "./pages/FileLibrary";
import { Subject } from "./pages/Subject";
import { Student } from "./pages/Student";
import { Login } from "./pages/Login";
import { Users } from "./pages/Users";
import { Verification } from "./pages/Verification";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoute from "./utils/ProtectedRoutes";
import Uploader from "./components/Uploader";
import { ClassRecLibrary } from "./pages/ClassRecordings";

function App() {
  return (
    <>
     <Uploader/>
      <Routes>
        <Route path={`/`} element={<Login />} />
        <Route path={`/forgot-password`} element={<ForgottenPassword />} />
        <Route path={`/verify-login`} element={<Verification />} />
        <Route path={`/reset-password`} element={<ResetPassword />} />
        <Route
          path={`/dashboard`}
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/file-library`}
          element={
            <ProtectedRoute>
              <FileLibrary />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/class-recordings`}
          element={
            <ProtectedRoute>
              <ClassRecLibrary />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/subject`}
          element={
            <ProtectedRoute>
              <Subject />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/student`}
          element={
            <ProtectedRoute>
              <Student />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/users`}
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
      </Routes>
      
    </>
  );
}

export default App;
