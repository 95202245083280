import React, { useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box, Typography } from "@mui/material";
import { UsersComponent } from "../../components/Users";
import GlobalPopupModal from "../../components/Popup/dialog";
import AddNewStudentComponent from "../../components/Users/AddNewSubjectComponent";
import { addStudentApi } from "../../services/api/student";
import Cookies from "js-cookie";
import ToastMessage from "../../components/ToastMessage";
import TransparentBdrBtn from "../../components/Button/TranparentBdrBtn";
import Icons from "../../components/Icons";

// Define the structure of the subject payload
interface SubPayload {
  subject_id: number;
  access_status: boolean;
}

// Define the structure of the student payload
interface addStuPayload {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  campus_id: number; // Make campus_id a number
  enrolment_status?: string;
  subjects?: SubPayload[];
}

export const Users = () => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString ? JSON.parse(verifyAccessString) : null;
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Initial student data with default values
  const initialStudentData: addStuPayload = {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    campus_id: 1, // Default campus_id as a number
    enrolment_status: "",
    subjects: []  // Set default as an empty array for subjects
  };

  const [toast, setToast] = useState<{ type: string; message: string } | null>(null);
  const [studentData, setStudentData] = useState(initialStudentData);

  // Disable the button if any required field is empty, including campus_id as number
  const isCreateButtonDisabled = !(
    studentData.first_name &&
    studentData.last_name &&
    studentData.phone &&
    studentData.email &&
    studentData.campus_id // Check that campus_id is not empty (number)
  );

  const updateStudentData = (data: Partial<addStuPayload>) => {
    setStudentData((prevData) => ({ ...prevData, ...data }));
  };

  const handleCloseModal = () => {
    setIsStudentModalOpen(false);
  };

  const handleAddStudent = () => {
    setIsStudentModalOpen(true);
  };

  const handleCreateNewStudent = async () => {
    // Validation: Ensure all fields are filled
    if (
      !studentData.first_name || 
      !studentData.last_name || 
      !studentData.phone || 
      !studentData.email || 
      !studentData.campus_id // Ensure campus_id is not empty (number)
    ) {
      console.error("Please fill in all required fields");
      return;
    }

    // Ensure enrolment_status is a string (default to empty string if undefined)
    const studentDataWithEnrolmentStatus = {
      ...studentData,
      enrolment_status: studentData.enrolment_status || "", // Default to empty string if undefined
      subjects: studentData.subjects || []  // Ensure subjects is always an array
    };

    setLoading(true);
    try {
      const token = verifyAccess?.token; // Use optional chaining to avoid errors if verifyAccess is null
      const response = await addStudentApi(studentDataWithEnrolmentStatus, token);
      handleCloseModal();
      const successMessage = response.data.message;
      setToast({
        type: "success",
        message: successMessage,
      });
    } catch (error) {
      console.error("Error adding student", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    
      <Dashboard>
        <div style={{ backgroundColor: "#fff", padding: "32px" }}>
          <Box display={"flex"} flexDirection={"column"} flexGrow={1} gap={"24px"} paddingRight={"24px"}>
            {toast && (
              <ToastMessage
                type={toast.type as "success" | "error"}
                message={toast.message}
                onClose={() => setToast(null)}
                style={{
                  position: "absolute",
                  top: "90px",
                  textTransform: "capitalize",
                }}
              />
            )}
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginRight: "auto" }} color={"contour.title2"} variant="title2" component={"div"}>
                Users
              </Typography>
              <TransparentBdrBtn
                text="Add a User"
                icon={<Icons name="add" fill="#333" width={16} height={16} />}
                textStyle={{ color: "#333" }}
                handleClick={handleAddStudent}
              />
            </Box>
            <UsersComponent />
          </Box>

          <GlobalPopupModal
            titleName="New User"
            btnText="Create new user"
            open={isStudentModalOpen}
            handleCloseModal={handleCloseModal}
            loading={loading}
            handleFooterBtn={handleCreateNewStudent}
            modalWidth={"720px"}
            isCreateButtonDisabled={isCreateButtonDisabled}
          >
            <AddNewStudentComponent isAddingStudent={true} updateStudentData={updateStudentData} />
          </GlobalPopupModal>
        </div>
      </Dashboard>
   
  );
};
