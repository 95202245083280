import { AxiosProgressEvent } from "axios";
import client from "./apiClientFileUpload";

export const uploadFileAPi = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.post("/files/upload-file/v2", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:onUploadProgress
  });
export const fileMergeAPi = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.post("/files/merge-chunks", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:onUploadProgress
  });
export const uploadFileAPiChunks = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.post("/files/chunk-upload", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:onUploadProgress
  });
export const uploadCRFileAPiChunks = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.post("/class_recording/chunk-upload-v1", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:onUploadProgress
  });
export const uploadFileAPiChunkstos3 = async (urlVal:string,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.put(urlVal, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:onUploadProgress
  });
export const initiateMultipartUpload = async (fileName: string, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.get(`/files/initiateMultipartUpload?fileName=${encodeURIComponent(fileName)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:onUploadProgress
  });

export const duplicateFileCheck = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.post("/files/validateDuplicateFile", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress:onUploadProgress
  });
export const duplicateCRFileCheck = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
  await client.post("/class_recording/validateDuplicateFile", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress:onUploadProgress
  });
