import { v4 as uuidv4 } from "uuid";
import { AppDispatch } from "../../store";
import { addFile, setFileStatus, setLoading, updateProgress } from "../../store/uploaderSlice";
import client from "./apiClientFileUpload";
import Cookies from "js-cookie";

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_CONCURRENT_UPLOADS = 1;
const verifyAccessString = Cookies.get("authAccess");
const verifyAccess = verifyAccessString ? JSON.parse(verifyAccessString) : null;

const uploadChunk = async (fileChunk: Blob, fileId: string, chunkNumber: number, totalChunks: number, fileType: string, fileName: string,uploadId:string,parts:any) => {

  const formData = new FormData();
  formData.append("originalname", fileName);
  formData.append("chunkNumber", `${chunkNumber}`);
  formData.append("totalChunks", `${totalChunks}`);
  formData.append('uploadId', `${uploadId}`);
  formData.append('parts', `${JSON.stringify(parts)}`);
  formData.append("chunk", new Blob([fileChunk], { type: fileType }), fileName); 


  await client.post("/class_recording/chunk-upload-v1", formData, {
    headers: {
      Authorization: `Bearer ${verifyAccess.token}`,
      "Content-Type": "multipart/form-data",
    }
  }).then((res)=>{
    res.data?.data && parts.push(res.data?.data)
  });
};

const uploadFile = (file: File, fileId: string,uploadId:any,parts:any) => async (dispatch: AppDispatch) => {
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  let uploadedChunks = 0;

  const uploadSingleChunk = async (chunkNumber: number) => {
    const start = chunkNumber * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const fileChunk = file.slice(start, end);

    try {
      await uploadChunk(fileChunk, fileId, chunkNumber + 1, totalChunks, file.type, file.name,uploadId,parts);
      uploadedChunks++;
      const progress = Math.round((uploadedChunks / totalChunks) * 100);
      dispatch(updateProgress({ fileId, progress }));
    } catch (error) {
      dispatch(setFileStatus({ fileId, status: "failed" }));
      dispatch(setLoading(false));
      throw error;
    }
  };

  const chunkUploadPromises: Promise<void>[] = [];
  for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
    const uploadPromise = uploadSingleChunk(chunkNumber);
    chunkUploadPromises.push(uploadPromise);

    if (chunkUploadPromises.length >= MAX_CONCURRENT_UPLOADS) {
      await Promise.all(chunkUploadPromises);
      chunkUploadPromises.length = 0;
    }
  }

  await Promise.all(chunkUploadPromises);
  dispatch(setFileStatus({ fileId, status: "completed" }));
};

export const uploadFilesInBatches = (files: File[]) => (dispatch: AppDispatch) => {
    const queue = files.slice();
    dispatch(setLoading(true));
    const activeUploads: Promise<void>[] = [];

    const processNextFile = () => {
      if (queue.length === 0 && activeUploads.length === 0) {
        dispatch(setLoading(false)); // All files and chunks are uploaded
        return;
      }
  
      const file = queue.shift();
      if (file) {
        const fileId = uuidv4();
        dispatch(addFile({ fileId, fileName: file.name, progress: 0, status: "uploading" }));
        client.get(`/class_recording/initiateMultipartUpload?fileName=${encodeURIComponent(file.name)}`, {
          headers: {
            Authorization: `Bearer ${verifyAccess.token}`,
            "Content-Type": "multipart/form-data",
          },
        }).then((res)=>{
          const parts:any=[]
          const uploadPromise = dispatch(uploadFile(file, fileId,res.data.data,parts)).then(() => {
            activeUploads.splice(activeUploads.indexOf(uploadPromise), 1);
            processNextFile(); // Check for next file in queue
          }).catch((reason)=>{
            console.log(reason,'reason')
            dispatch(setLoading(false));
  
          });
    
          activeUploads.push(uploadPromise);
        })
        if (activeUploads.length < MAX_CONCURRENT_UPLOADS) processNextFile();
      }
    };
  
    // Start the initial batch
    for (let i = 0; i < MAX_CONCURRENT_UPLOADS; i++) processNextFile();
  };
