import React from "react";
import { Box } from "@mui/material";
import StudentTData from "../Users/StudentTTData";
import CustomizedAccordions from "../Users/CustomizedAccordions";

type Student = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: string;
  mst_student_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  access_start_date: string | null;
  access_end_date: string | null;
  access_status: boolean;
  campus_id: string;
};

type TableData = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: number;
  sub_code: string;
  label: string;
  students: Student[];
};

interface StudentTDataProps {
  stuData: TableData[];
}
interface TableComponentProps {
  selectedTab: string;
  handleSelectionChange: (count: number, countData: number[]) => void;
  stuData: StudentTDataProps["stuData"];
  loading: boolean;
  fetchData?: (select: string) => Promise<void>;
  tableLoading?: any;
  rowCountState?: any;
  paginationModel?: any;
  setPaginationModel?: any;
}

const TableComponent: React.FC<TableComponentProps> = ({
  selectedTab,
  handleSelectionChange,
  stuData,
  loading,
  fetchData,
  tableLoading,
  rowCountState,
  paginationModel,
  setPaginationModel,
}) => {
  return (
    <>
      <Box sx={{}}>
        {selectedTab === "Student" ? (
          <StudentTData stuData={stuData} loading={loading}
            tableLoading={tableLoading}
            rowCountState={rowCountState}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        ) : (
          <Box>
            <CustomizedAccordions
              onSelectionChange={handleSelectionChange}
              stuData={stuData || []}
              loading={loading}
              fetchData={fetchData}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default TableComponent;
